import React from 'react';
import './entra.css'; // Ensure to import your CSS file
import Entraim from '../../img/entraprenuerpage.png'
import WebProducts from '../products/WebProducts';

import Footer from '../footer/Footer';
import { useShop } from '../context/ShopContext';
const Entra = () => {
  const shopData = useShop();
  return (
    <>
        <div className='row'> 
          <div className='col-md-12'>
            <img src={Entraim} alt="banner image  for the entrepreneur page section" className='entra-page-banner' />
            <br /><br />
            <h1 className='entra-header'>Empowering new ventures with innovative solutions</h1>
            <br />
            <p className='entra-para-imp'>Our Services are Shaped by Your Requirements, Not by the Cost</p>
          </div>
          </div>
          <br/><br />

        <div className='row'>
            {shopData.slice(0,8).map((dat)=>(
              <WebProducts name={dat.name} id={dat.id} description={dat.description} price={dat.price} price_type={dat.price_type} features={dat.features} image={dat.image} alt={dat.alt} />
            ))}
          </div>
        <br />

        <div className='row foo'>
        <Footer />
      </div>



    </>
  );
}

export default Entra;
