import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";
import SuccessPopup from "../successresponse/SuccessPopup";
import ErrorPopup from "../errorresponse/ErrorPopup";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setSuccessMessage("Login successful! Redirecting...");
      setShowSuccess(true);
      setShowError(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message); 
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => {
        setShowSuccess(false);
        history.push("/");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSuccess, history]);

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <p>to get started</p>

        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <p className="forgot-password">Forgot Password?</p>

        <button type="submit" className="submit-button">
          Continue
        </button>

        <p className="register-text">
          New user? <a href="/register">Register</a>
        </p>
      </form>

      {showSuccess && <SuccessPopup message={successMessage}/>}
      {showError && <ErrorPopup message={errorMessage} />}
    </div>
  );
}

export default Login;