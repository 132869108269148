import React from "react";
import "../navbar/navbar.css";
import { Link } from "react-router-dom";

const Navbar = ({ user, onLogout }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Addnex.
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/about">
                  About
                </a>
              </li>{" "}
              <li className="nav-item">
                <a
                  className="nav-link "
                  aria-current="page"
                  href="/our-services"
                >
                  Our Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/shop">
                  Our Offerings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/blog">
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="/contact">
                  Contact us
                </a>
              </li>
              {/* {user ? (
                <li className="nav-item">
                  <button className="nav-link logout-button" onClick={onLogout}>
                    Logout
                  </button>
                </li>
              ) : (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Account
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/login">
                        Login
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/register">
                        SignUp
                      </a>
                    </li>
                  </ul>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
