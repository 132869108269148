import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";

import { setDoc, doc } from "firebase/firestore";
import { auth } from "../firebase";
import { dblClick } from "@testing-library/user-event/dist/click";
import "../register/register.css";
import { useHistory } from "react-router-dom";
import SuccessPopup from "../successresponse/SuccessPopup";
import ErrorPopup from "../errorresponse/ErrorPopup";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      console.log(user);
      if (user) {
        await setDoc(doc(dblClick, "Users", user.uid), {
          email: user.email,
          firstName: fname,
          lastName: lname,
          photo: "",
        });
      }
      console.log("User Registered Successfully!!");
      setSuccessMessage("Register successful! Redirecting...");
      setShowSuccess(true);
      setShowError(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message); 
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => {
        setShowSuccess(false);
        history.push("/");
      }, 2000); 
      return () => clearTimeout(timer);
    }
  }, [showSuccess, history]);

  return (
    <div className="register-container">
      <form onSubmit={handleRegister} className="register-form">
        <h2>Sign Up</h2>

        <div className="input-group">
          <input
            type="text"
            placeholder="First name"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <input
            type="text"
            placeholder="Last name"
            value={lname}
            onChange={(e) => setLname(e.target.value)}
          />
        </div>

        <div className="input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Sign Up
        </button>

        <p className="register-login-text">
          Already registered? <a href="/login">Login</a>
        </p>
      </form>
      {showSuccess && <SuccessPopup message={successMessage}/>}
      {showError && <ErrorPopup message={errorMessage} />}
    </div>
  );
}
export default Register;
