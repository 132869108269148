import React, { createContext, useContext } from 'react';
import ShopData from "../../productasset"; // Adjust the path if needed

const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  return (
    <ShopContext.Provider value={ShopData}>
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => useContext(ShopContext);
