import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import About from "./components/aboutus/About";
import Footer from "./components/footer/Footer";
import Service from "./components/service/Service";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import pageNotFound from "./components/pageNotFound";
import Entra from "./components/entra/Entra";
import ContactPage from "./components/pages/ContactPage";
import Influ from "./components/influencer/Influ";
import Blogs from "./components/blogs/Blogs";
import SinglePost from "./components/blogs/SinglePost";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import { auth } from "./components/firebase";
import ProtectedRoute from "./components/ProtectedRoute";
import { onAuthStateChanged, signOut } from "firebase/auth";

const App = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        setUser(null);
        localStorage.removeItem("user");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        localStorage.removeItem("user");
      })
      .catch((error) => console.error("Logout failed:", error));
  };

  return (
    <div>
      <div className="container-fluid">
        <Navbar user={user} onLogout={handleLogout} />
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* {!user && <Route path="/login" component={Login} />}
            {!user && <Route path="/register" component={Register} />} */}
            <Route path="/about" component={About} user={user} />
            <Route
              path="/our-services"
              component={Services}
              // user={user}
            />
            <Route
              path="/service/:id"
              component={Service}
              // user={user}
            />
            <Route
              path="/contact"
              component={ContactPage}
              // user={user}
            />
            <Route path="/shop" component={Entra} />
            <Route path="/influencer" component={Influ} />
            <Route path="/blog" component={Blogs} />
            <Route
              path="/single-post"
              component={SinglePost}
              user={user}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default App;
